<template>
  <section class="relative w-full h-screen/2 md:h-screen">
    <div class="fixed w-full h-screen/2 md:h-screen -z-10">
      <img class="w-full h-full object-cover" src="@/assets/values.jpg" />
      <div class="absolute inset-0 bg-opacity-50 flex items-center justify-center bg-black text-white py-2 px-4">
        <AnimateText title="OUR VALUES" />
      </div>
    </div>
  </section>

  <section class="bg-white">
    <div class="flex flex-col md:flex-row items-center">
      <div class="md:w-1/2 p-4 md:p-0">
        <img class="img-fluid" src="@/assets/our_services_1.jpg" />
      </div>

      <div class="md:w-1/2 p-8">
        <h2 class="text-3xl text-primary text-center mb-8">Consultation and Design</h2>
        <p class="xl:text-lg mb-8">
          Our design team consists of professional and experienced individuals with competent and qualified
          architectural, interior design, and civil backgrounds, which prove invaluable asset as our clients demand a
          limitless references to realize their plans and dreams.
        </p>
        <p class="xl:text-lg">
          We also collaborated with international designers from France and Canada with its unique approach in search of
          exploring design characteristic and style that clients want.
        </p>
      </div>
    </div>
  </section>

  <section class="bg-white">
    <div class="flex flex-col-reverse md:flex-row items-center pt-8 md:pt-0">
      <div class="md:w-1/2 p-8">
        <h2 class="text-3xl text-primary text-center mb-8">Construction and Production</h2>
        <div class="quote m-8">
          <h2 class="text-primary text-center">EVERY ELEMENT IS HANDLED BY SPECIALIST WORKERS.</h2>
        </div>
        <p class="xl:text-lg mb-8">
          Our production team are experts in residential, office, commercial, up to high-rise building construction. Our
          workshop is also equipped with shophisticated machines (CNC router, laser cut, etc.) to support optimal
          cutting precision and neatness.
        </p>
        <p class="xl:text-lg">
          The management is commited to running a good management of project preparation, coordination, and supervision
          to maintain quality in accordance of technical drawing and specification.
        </p>
      </div>

      <div class="md:w-1/2 p-4 md:p-0"><img class="img-fluid" src="@/assets/our_services_2.jpg" /></div>
    </div>
  </section>
</template>

<script>
import { useHead } from '@vueuse/head';
import AnimateText from '@/components/Animation/AnimateText.vue';

export default {
  components: {
    AnimateText,
  },
  setup() {
    useHead({
      title: `Our Values | ${process.env.VUE_APP_NAME}`,
    });
  },
};
</script>
